import { network } from '@/services/network'
import _ from 'lodash'
import { setLocale, localeType } from '@/services/i18n-ttm'
import { ESocketStatus, ISocketMessage } from '@/services/network-if'
import { EViewType, IAppearance, IKeyValue, IPushService, ISettings } from '@/services/config-if'
import { Mutex } from 'async-mutex'
import VueCookies from 'vue-cookies'
import { useTTMStore } from '@/services/store'

class Configuration {
    private settings: ISettings | null = null;
    private cfgCallback: { (): void; } [] = [];
    private loadMutex = new Mutex();

    constructor () {
        this.load().then(() => {
            // DONE
        })
        network.setWebsocketCallback(this.sockReceiver.bind(this))
    }

    setConfigCallback (cfgCallback: { ():void; }) {
        this.cfgCallback.push(cfgCallback)
    }

    async load () {
        const release = await this.loadMutex.acquire()
        const recv = await network.apiRequest('/api/monitor/get-config') as { configuration: ISettings }
        const config = recv?.configuration ?? null

        if (config) {
            this.settings = _.cloneDeep(config)
            setLocale(this.language as localeType)

            const store = useTTMStore()
            store.setBrand(this.settings.brand)
        }

        release()
    }

    loadAppearance () {
        if (VueCookies.isKey('appearance')) {
            const data = VueCookies.get('appearance')
            return data as IAppearance
        }
        return this.settings?.appearance
    }

    write () {
        if (this.settings) {
            network.apiPost('/api/monitor/set-config', this.settings).then(() => {
                // DONE
            })
        }
    }

    sockReceiver (data: string) {
        try {
            const msg = JSON.parse(data) as unknown as ISocketMessage
            if (msg.status === ESocketStatus.CONFIGURATION_CHANGED) {
                this.load().then(() => {
                    this.cfgCallback.forEach(cb => cb())
                })
            }
        } catch (e) {
            // invalid data -> will not processed
        }
    }

    // GETTER
    public get language () {
        return this.settings?.region.language ?? 'en'
    }

    public get measUnit () {
        return this.settings?.region.unit ?? 'mm'
    }

    public get locale () {
        return this.settings?.region.locale ?? 'en-US'
    }

    public get company () {
        return this.settings?.workshop.company ?? ''
    }

    public get address () {
        return this.settings?.workshop.address ?? ''
    }

    public get city () {
        return this.settings?.workshop.zipCity ?? ''
    }

    public get web () {
        return this.settings?.workshop.web ?? ''
    }

    public get email () {
        return this.settings?.workshop.email ?? ''
    }

    public get logo () {
        return this.settings?.workshop.logo ?? ''
    }

    public get consideredCrossingDirection () {
        return this.settings?.measure.consideredCrossingDirection ?? 'both'
    }

    public get stations () {
        return this.settings?.Stations ?? null
    }

    public get DeepGrooveEnabled () {
        return this.settings?.measure.deepGroove ?? true
    }

    public get SwapValuesOnReversing () {
        return this.settings?.measure.swapValuesOnReversing ?? false
    }

    public get WinterLimitsEnabled () {
        return this.settings?.measure.useWinterLimits ?? false
    }

    public get WinterLimits () {
        if (this.settings) {
            return [
                this.settings.measure.winterLimits.minimum,
                this.settings.measure.winterLimits.recommended
            ]
        } else {
            return [3, 4]
        }
    }

    public get SummerLimits () {
        if (this.settings) {
            return [
                this.settings.measure.summerLimits.minimum,
                this.settings.measure.summerLimits.recommended
            ]
        } else {
            return [2, 3]
        }
    }

    public get LinearWearPatternLimit () {
        return this.settings?.measure.linearWearPatternLimit ?? 0.1
    }

    public get OrderAgentAutoStart () {
        return this.settings?.orderAgent.autoStart ?? false
    }

    public get AsaNetworkEnabled () {
        return this.settings?.orderAgent.asaNetworkEnabled ?? false
    }

    public get pushServices () {
        return this.settings?.push ?? []
    }

    public stationName (ipAddr: string) {
        if (this.stations) {
            for (const ip in this.stations) {
                if (ip === ipAddr || this.stations[ip].master === ipAddr) {
                    return this.stations[ip].name
                }
            }
        }
        return ''
    }

    public get viewType () {
        const appearance = this.loadAppearance()
        return appearance?.crossingMonitor?.viewType ?? EViewType.Expert
    }

    public get resultsValueDuration () {
        const appearance = this.loadAppearance()
        return appearance?.crossingMonitor?.resultsViewDuration ?? 15
    }

    public get showMonitorLane () {
        const appearance = this.loadAppearance()
        return appearance?.liveMonitor?.showLane ?? true
    }

    public get vehicleEntrysHideDelay () {
        const appearance = this.loadAppearance()
        return appearance?.liveMonitor?.hideAfterSecs ?? 0
    }

    public get liteSkin () {
        const appearance = this.loadAppearance()
        return appearance?.crossingMonitor?.liteSkin ?? false
    }

    public get ShowAnprCountry () {
        const appearance = this.loadAppearance()
        return appearance?.plateRecognition?.showCountry ?? false
    }

    public get fileLogging () {
        return this.settings?.fileLogging ?? false
    }

    public get axiswiseTimeout () {
        return this.settings?.measure.axiswiseTimeoutMin ?? 5
    }

    // SETTER

    public setRegionItems (data: IKeyValue []) {
        if (this.settings) {
            for (const item of data) {
                if (item.key === 'language') {
                    this.settings.region.language = item.value as string
                    setLocale(this.language as localeType)
                } else if (item.key === 'locale') {
                    this.settings.region.locale = item.value as string
                } else if (item.key === 'unit') {
                    this.settings.region.unit = item.value as string
                }
            }
            this.write()
        }
    }

    public setWorkshopItems (data: IKeyValue []) {
        if (this.settings) {
            for (const item of data) {
                if (item.key === 'company') {
                    this.settings.workshop.company = item.value as string
                } else if (item.key === 'address') {
                    this.settings.workshop.address = item.value as string
                } else if (item.key === 'city') {
                    this.settings.workshop.zipCity = item.value as string
                } else if (item.key === 'web') {
                    this.settings.workshop.web = item.value as string
                } else if (item.key === 'email') {
                    this.settings.workshop.email = item.value as string
                }
            }
            this.write()
        }
    }

    public setWinterEnabled (value: boolean) {
        if (this.settings) {
            this.settings.measure.useWinterLimits = value
            this.write()
        }
    }

    public setLinearWearPatternLimit (value: number) {
        if (this.settings) {
            this.settings.measure.linearWearPatternLimit = value
            this.write()
        }
    }

    public setSummerLimits (min: number, rec: number) {
        if (this.settings) {
            this.settings.measure.summerLimits.minimum = min
            this.settings.measure.summerLimits.recommended = rec
            this.write()
        }
    }

    public setWinterLimits (min: number, rec: number) {
        if (this.settings) {
            this.settings.measure.winterLimits.minimum = min
            this.settings.measure.winterLimits.recommended = rec
            this.write()
        }
    }

    public setConsideredDirection (value: string) {
        if (this.settings) {
            this.settings.measure.consideredCrossingDirection = value
            this.write()
        }
    }

    public setDeepGroove (value: boolean) {
        if (this.settings) {
            this.settings.measure.deepGroove = value
            this.write()
        }
    }

    public setSwapValuesOnReversing (value: boolean) {
        if (this.settings) {
            this.settings.measure.swapValuesOnReversing = value
            this.write()
        }
    }

    public setOrderAgentAutoStart (value: boolean) {
        if (this.settings) {
            this.settings.orderAgent.autoStart = value
            this.write()
        }
    }

    public setAsaNetworkEnabled (value: boolean) {
        if (this.settings) {
            this.settings.orderAgent.asaNetworkEnabled = value
            this.write()
        }
    }

    public setPushReceiver (value: IPushService []) {
        if (this.settings) {
            this.settings.push = value
            this.write()
        }
    }

    public setViewType (value: EViewType, setAsDefault: boolean) {
        if (this.settings) {
            this.settings.appearance.crossingMonitor.viewType = value
            VueCookies.remove('appearance')
            VueCookies.set('appearance', this.settings.appearance)

            if (setAsDefault) {
                this.write()
            }
        }
    }

    public setResultsViewDuration (value: number, setAsDefault: boolean) {
        if (this.settings) {
            this.settings.appearance.crossingMonitor.resultsViewDuration = value
            VueCookies.remove('appearance')
            VueCookies.set('appearance', this.settings.appearance)

            if (setAsDefault) {
                this.write()
            }
        }
    }

    public setShowMonitorLane (value: boolean, setAsDefault: boolean) {
        if (this.settings) {
            this.settings.appearance.liveMonitor.showLane = value
            VueCookies.remove('appearance')
            VueCookies.set('appearance', this.settings.appearance)

            if (setAsDefault) {
                this.write()
            }
        }
    }

    public setVehicleEntrysHideDelay (value: number, setAsDefault: boolean) {
        if (this.settings) {
            this.settings.appearance.liveMonitor.hideAfterSecs = value
            VueCookies.remove('appearance')
            VueCookies.set('appearance', this.settings.appearance)

            if (setAsDefault) {
                this.write()
            }
        }
    }

    public setLiteSkin (value: boolean, setAsDefault: boolean) {
        if (this.settings) {
            this.settings.appearance.crossingMonitor.liteSkin = value
            VueCookies.remove('appearance')
            VueCookies.set('appearance', this.settings.appearance)

            if (setAsDefault) {
                this.write()
            }
        }
    }

    public setShowAnprCountry (value: boolean) {
        if (this.settings) {
            this.settings.appearance.plateRecognition.showCountry = value
            VueCookies.remove('appearance')
            VueCookies.set('appearance', this.settings.appearance)
            this.write()
        }
    }

    public setFileLogging (value: boolean) {
        if (this.settings) {
            this.settings.fileLogging = value
            this.write()
        }
    }

    public setBrand (value: string) {
        if (this.settings) {
            this.settings.brand = value

            const store = useTTMStore()
            store.setBrand(value)
            this.write()
        }
    }

    public setAxiswiseTimeout (value: number) {
        if (this.settings) {
            this.settings.measure.axiswiseTimeoutMin = value
            this.write()
        }
    }
}

const ttmConfig = new Configuration()
export { ttmConfig }
