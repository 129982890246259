/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IQueryTreadValue } from '@/services/query-api-if'

export interface IVersion {
    version: string;
    platform: string;
}

export enum ESocketStatus {
    OK,
    CONNECTION_OPENED,
    CONNECTION_LOST,
    CONNECTION_STATUS_MODULE,
    CONFIGURATION_CHANGED,
    SETUP_CANCELLED,
    ORDERAGENT_RESULT,
    DATABASE_UPDATE,
    WIRELESS_CONNECTIONS,
    ADAPTERS_CHANGED,
    HOSTNAME_CHANGED,
    REBOOT_IN_PROGRESS,
    LOCALE_CHANGED
}

export interface ISocketMessage {
    status: ESocketStatus;
    data: any;
}

export enum ETirePosition {
    FL,
    FR,
    RL,
    RR
}

export const WPStr = {
    ok: 'ok',
    linearWearLeft: 'linearWearLeft',
    linearWearRight: 'linearWearRight',
    parabolicWearOutside: 'parabolicWearOutside',
    parabolicWearCentered: 'parabolicWearCentered'
}

export interface IProfileScheme{
    left: IQueryTreadValue;
    middle: IQueryTreadValue;
    right: IQueryTreadValue;
}

// NETWORK CONFIGURATION

export interface IWirelessConnection {
    ssid: string;
    bssid: string;
    mac: string;
    channel: number;
    frequency: number;
    signal_level: number;
    quality: number;
    security: string;
    group_cipher: string;
    pairwise_ciphers: string;
    mode: string;
 }

export interface IHwAdapter {
    name: string;
    ipv4_connected_addr: string;
    ipv4_address: string;
    ipv4_subnet_mask: string;
    mac: string;
    up: boolean;
    ssid: string;
    dhcp: boolean;
    country: string;
    gateway: string;
    dns: string;
}

export interface IWirelessSetup {
    ssid: string;
    psk: string;
    key_mgmt: string;
    pairwise: string;
    group: string;
    country: string;
    priority: number;
}

export interface IDhcpSetup {
    adapter: string;
    dhcpOff: boolean;
    ipAddr: string;
    netMask: string;
    dnsServer: string;
    gatewayAddr: string;
}

// LANE BACKUP

export enum ELaneBackupStatus {
    ProcessIdle,
    ProcessRun,
    ResultOk,
    ResultFailed
}

export interface ILaneBackupStatus {
    files: string [];
    processStatus: ELaneBackupStatus;
    resultStatus: {
        status: ELaneBackupStatus;
        timestamp: string;
        lane: string;
    };
}
