const paths = {
    HOME: '/',
    MONITOR: '/monitor',
    SETTINGS: '/settings',
    STATISTICS: '/statistics',
    CROSSING: '/crossing/:lane',
    HELP: '/help'
}

Object.freeze(paths)
export { paths }
